/** @format */

#root {
  margin: 0;
  padding: 0;
  height: 100vh;
}

html,
body {
  margin: 0;
  height: 100vh;
  padding: 0;
  box-sizing: border-box;
  overflow-y: hidden;
}
/* RCA Chart Modified Scroll Bar */
.vitals-history-body::-webkit-scrollbar {
  width: 0.4em;
}

.vitals-history-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.vitals-history-body::-webkit-scrollbar-thumb {
  background-color: #afc3d6;
}
/* RCA Chart Modified Scroll Bar */

::-moz-selection {
  color: white;
  background: #3e4685;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

::selection {
  color: white;
  background: #3e4685;
}

.app-sidebar {
  background: inherit;
  border: none;

  .sidebar-active-list {
    background-color: #d8e5fd;
  }

  .sidebar-inactive-list {
    background-color: transparent;
  }
}

.app-header {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  .header-user-role-chip {
    height: fit-content;
    width: fit-content;
    background: #3e4685;
    border-radius: 50px;
  }

  .header-user-role-text {
    padding: 2px 9px 4px 9px;
  }
}

.card-section-container {
  background: #f5f3f3;
  border-radius: 5px;
  padding: 16px;

  .header-text {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;

    padding-bottom: 16px;
  }
}

.week-timeline {
  border-left: 2px solid black !important;
}

.no-border {
  border: none !important;
}

.bg-white {
  background: transparent !important;
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px;
}
.zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.card-section-container {
  background: #f5f3f3;
  border-radius: 5px;
  padding: 16px;
}

.card-section-container .header-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 16px;
}

.next-review-date {
  border-left: 2px solid black !important;
}

.no-border {
  border: none !important;
}

.bg-white {
  background: transparent !important;
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px;
}

.zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}
/*# sourceMappingURL=global.css.map */
ol {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 165.5%;
  /* or 23px */

  letter-spacing: 0.01em;

  /* Secondary Text Color */

  color: #98a1b9;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Adding Scroll Bar to Vitals History
.vitals-history-body {
  // overflow: auto;
  height: min-content;
  max-height: inherit;
}

.custom-text-wrap {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-transparent {
  background-color: transparent;
}
.hiddeErrorMessage {
  display: none;
}
.showErrorMessage {
  display: block;
}

.input-dropdown-small {
  .MuiSelect-select {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4.8px;
    padding-bottom: 1.6px;
  }

  .MuiFormHelperText-root {
    display: none;
  }

  .MuiTypography-root {
    font-size: 12px;
  }
}

.text-break-line {
  // text-align:justify;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

#appointment-item-container {
  padding: 16px;

  #error-field-template {
    min-height: 15px;
  }

  .MuiInputBase-root,
  .MuiInputBase-root {
    height: 45px;
    min-height: 45px;
  }

  #root_daysBeforeConsultation {
    display: flex;
    flex-direction: row-reverse;

    .MuiFormLabel-root {
      .MuiTypography-root {
        font-weight: 400;
      }
    }

    .MuiInputBase-root {
      width: 30%;
    }
  }
}

.rca-custom-table {
  .custom-table {
    td {
      padding: 4px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }
}
