/** @format */

.save-draft-button {
  all: unset;
  cursor: pointer;

  padding: 8px 16px;
  background-color: #eaf2fc;
  border-radius: 4px;
  display: flex;
  align-items: center;

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.save-approve-button {
  all: unset;
  cursor: pointer;

  color: white;
  padding: 8px 16px;
  background-color: #3e4685;
  border-radius: 4px;
  display: flex;
  align-items: center;

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.save-approve-button-disabled {
  all: unset;
  cursor: pointer;

  border: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  cursor: not-allowed;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

#patient-enrolment-form-container {
  .form-control.phone-input-input {
    &:focus {
      border: 1.5px solid #33425b !important;
    }
    &:active {
      border: 1.5px solid #33425b !important;
    }
    &:hover {
      border: 1.5px solid #33425b !important;
    }
  }

  #grid-template-areas-patient-basic {
    grid-template-columns: 22% 22% 22% 22%;
    grid-gap: 0 40px;
    grid-template-areas:
      'enrollment-date patient-id first-name last-name'
      'birth-date gender ethnicity .'
      'address address phone-number email'
      'phone-number-alt relationship-alt-contact relationship-alt-contact-name .';
  }

  #grid-template-areas-medical-history {
    grid-template-columns: 18% 18% 18% 18% 18% !important;
    grid-gap: 0 40px !important;
    grid-template-areas:
      'hf-type date-of-hf-diagnosis lvef nyha-class last-hospitalization-date'
      'diseases-symptoms diseases-symptoms diseases-symptoms diseases-symptoms diseases-symptoms'
      'allergies allergies allergies allergies allergies'
      'vitals-and-parameters vitals-and-parameters vitals-and-parameters vitals-and-parameters vitals-and-parameters';
  }

  #grid-template-areas-diseases-symptoms {
    background-color: #fafbfc;
    grid-template-columns: 35% 28% 28%;
    grid-gap: 0 40px;
    grid-template-areas: 'cardio-vascular-diseases non-cardio-vascular-diseases symptoms';
  }

  #grid-template-areas-vitals-grid {
    padding-left: 40px;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 0 40px;
    grid-template-areas:
      'bp bnp glucose'
      'hr ntprobnp blood-urea-nitrogen'
      'weight scr hemoglobin'
      'height egfr hematocrit'
      'bmi potassium hba1c'
      '. calcium bicarbonate'
      '. sodium chloride';
  }

  #grid-template-areas-physician {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }

  #grid-template-areas-prev-medications {
    // grid-template-columns: ;
  }

  .hospitalization {
    border: 10px solid red;
    background-color: red;
  }
}
